<template>
  <div class="loc-modal__container">
    <div class="loc-modal__overlay" @click="$emit('close')"></div>
    <div class="loc-modal">
      <div class="loc-modal__close" @click="$emit('close')"></div>
      <div class="loc-modal__head">
        <div class="loc-modal__head-icon">
          <img :src="img" />
        </div>
        <div class="loc-modal__head-title" v-html="title"></div>
      </div>
      <ul class="loc-modal__list">
        <li v-for="(li, ind) in items" :key="ind" v-html="li"></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocalizationModal",
  props: {
    img: String,
    title: String,
    items: Array,
  },
};
</script>

<style lang="scss" scoped>
.loc-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 344px;
  height: fit-content;
  border-radius: 32px;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 30px 50px -30px #00000059;
  padding: 20px;
  background-color: #fff;
  z-index: 1000;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      width: 12px;
      height: 2px;
      border-radius: 999px;
      background-color: #3c4242;
      content: "";
      display: block;
    }
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(31, 31, 31, 0.6);
    transition: opacity 0.3s ease;
    z-index: 999;
  }

  &__head {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-icon {
      width: 40px;
      flex-shrink: 0;
      margin-right: 20px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
      color: #830051;
    }
  }

  &__list {
    padding-left: 25px;
    list-style-type: disc;

    & li {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }
}
</style>